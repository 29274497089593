import { FC, useState } from 'react';
import { Box, Grid, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { Rating, Badge } from '@care/react-component-lib';
import { Icon24InfoBgcFilled, Icon24InfoPremiumFilled } from '@care/react-icons';

export type BadgeType = 'PREMIUM' | 'BACKGROUND_CHECK' | undefined;
export type BadgeState = {
  PREMIUM: boolean;
  BACKGROUND_CHECK: boolean;
};

export interface ProfileDataProps {
  /**
   * Caregiver display name
   */
  displayName: string;
  /**
   * Group of badges present on the profile
   */
  badges?: BadgeType[];
  /**
   * Default badge order
   */
  badgeOrder?: BadgeType[];
  /**
   * Optional prop to display background check text
   */
  showBackgroundCheckText?: boolean;
  /**
   * Caregiver profile location
   */
  neighborhood?: string;
  /**
   * Rate, in currency per duration
   */
  rate?: number;
  /**
   * Duration for rate
   */
  duration?: string;
  /**
   * Star rating from 0 to 5 and review count
   */
  rating?: {
    value: number;
    count: number | string;
  };
  /**
   * Years of experience
   */
  experience?: number;
  /**
   * Optional prop to display vertical name for empty dataset i.e. 'New to Childcare'
   */
  vertical?: string;
  /**
   * Optional prop to display date alongisde of CareCheck badge
   */
  careCheckDate?: string | null;
}

const classes: Record<string, SxProps<Theme>> = {
  badge: {
    ml: 0.5,
    mr: -1,
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
    top: '3px',
  },
};

const badgesData = {
  PREMIUM: {
    testId: 'premium-badge',
    tooltipText: 'This is the premium badge',
    icon: <Icon24InfoPremiumFilled />,
  },
  BACKGROUND_CHECK: {
    testId: 'bgc-badge',
    tooltipText: 'This is the background check badge',
    icon: <Icon24InfoBgcFilled />,
    text: 'Background checked',
  },
};

/**
 * A collection of primary data about the caregiver such as name, neighborhood,
 * rating, and their relevant badges.
 */
export const ProfileData: FC<ProfileDataProps> = ({
  displayName,
  badges = [],
  badgeOrder = ['PREMIUM', 'BACKGROUND_CHECK'],
  showBackgroundCheckText = false,
  neighborhood,
  rate,
  duration = 'hour',
  rating,
  experience,
  vertical,
  careCheckDate,
  ...rest
}) => {
  const sortedBadges =
    badges.length > 0 ? badgeOrder.filter((badge) => badges.includes(badge)) : [];

  const showExperience = !rating && experience && experience > 0;
  const showNewToCare = !rating && !experience;

  const [tooltipsOpen, setTooltipsOpen] = useState<BadgeState>({
    PREMIUM: false,
    BACKGROUND_CHECK: false,
  });

  const handleTooltipOpen = (badgeType: 'PREMIUM' | 'BACKGROUND_CHECK') => {
    setTooltipsOpen({ ...tooltipsOpen, [badgeType]: !tooltipsOpen[badgeType] });
  };

  const getTipHtml = (badgeType: BadgeType) => {
    let badgeText;
    if (badgeType === 'PREMIUM') {
      badgeText = 'Premium';
    } else if (badgeType === 'BACKGROUND_CHECK') {
      badgeText =
        careCheckDate && careCheckDate.length
          ? `CareCheck completed as of ${careCheckDate}`
          : 'CareCheck completed';
    }
    return (
      <Typography
        color="inherit"
        aria-label={`${badgeType?.toLowerCase()} tooltip`}
        variant="body3">
        {badgeText}
      </Typography>
    );
  };

  return (
    <Grid container {...rest}>
      <Grid item container alignItems="center">
        <Typography variant="h2" component="div">
          {displayName}
          {sortedBadges.length > 0 &&
            sortedBadges.map((badgeType, index) => {
              if (!badgeType) return null;
              if (badgeType === 'BACKGROUND_CHECK' && showBackgroundCheckText) {
                return (
                  <Box display="inline-flex" alignItems="center" key={`badge-${index + 1}`}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      title={getTipHtml(badgeType)}
                      placement="bottom"
                      arrow>
                      <Badge
                        data-testid={badgesData[badgeType].testId}
                        onMouseOver={() => handleTooltipOpen(badgeType)}
                        icon={badgesData[badgeType].icon}
                        sx={classes.badge}
                      />
                    </Tooltip>
                    <Typography
                      component="span"
                      ml={0.5}
                      fontSize={(theme) => theme.typography.badge.fontSize}
                      color={(theme) => theme.palette.care.grey[600]}
                      mt={0.5}>
                      Background checked
                    </Typography>
                  </Box>
                );
              }

              return (
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={getTipHtml(badgeType)}
                  placement="bottom"
                  arrow>
                  <Badge
                    data-testid={badgesData[badgeType].testId}
                    onMouseOver={() => handleTooltipOpen(badgeType)}
                    icon={badgesData[badgeType].icon}
                    sx={classes.badge}
                  />
                </Tooltip>
              );
            })}
        </Typography>
      </Grid>
      {neighborhood && (
        <Grid item container>
          <Typography
            variant="body2"
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                maxWidth: '162px',
              },
            })}>
            {neighborhood}
          </Typography>
        </Grid>
      )}
      {rate !== undefined && rate > 0 && (
        <Grid item container>
          <Typography variant="h4" component="p" whiteSpace="nowrap">
            from ${rate} per {duration ?? 'hour'}
          </Typography>
        </Grid>
      )}
      {Boolean(rating?.value) && (
        <Grid item container alignItems="center" pt={0.5}>
          <Rating readOnly size="medium" value={rating?.value} precision={0.1} />
          {Boolean(rating?.count) && <Typography variant="info1">({rating?.count})</Typography>}
        </Grid>
      )}
      {!!showExperience && (
        <Grid item container>
          <Typography variant="body2">
            {experience >= 10 ? '10+' : experience} {experience === 1 ? 'year' : 'years'} of
            experience
          </Typography>
        </Grid>
      )}
      {showNewToCare && (
        <Grid item container>
          <Typography variant="body2">New to {vertical || 'Care'}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
